import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const NikitaPospelovAppointment = () => {
  return (
    <>
      <SEO
        title="Nikita Pospelov"
        description="Nikita Pospelov became new Global Business Director at SIGNAX"
      />
      <NewsSectionBlock date="May 08, 2024">
        <Typography variant="h1" color="blue">
          {`Nikita Pospelov became new\n Global Business Director at SIGNAX`}
        </Typography>
        <div className={s.authorWrapper}>
          <StaticImage
            width={96}
            src="../assets/images/news/nikita-pospelov-appointment/avatar.png"
            alt="Roman Sigitov"
            title="Roman Sigitov"
            placeholder="blurred"
          />
          <div className="flex flex-col ml-4">
            <Typography variant="body2" color="blue" size={24}>
              Roman Sigitov
            </Typography>
            <Typography variant="body2" color="gray" size={16} className="mt-2">
              CEO at SIGNAX
            </Typography>
          </div>
        </div>
        <Typography variant="body1">
          I am pleased to announce the appointment of Nikita Pospelov as our new
          Global Business Director at SIGNAX.
        </Typography>
        <Typography variant="body1">
          In his new role, Nikita will focus on developing and maintaining our
          global network of prospects, while also managing relationships with
          BIM stakeholders. He will serve as our brand ambassador, expanding
          awareness of SIGNAX worldwide, and will coordinate the work of our
          engineering team in the MENA region.
        </Typography>
        <Typography variant="body1">
          {`Nikita has been involved in digital construction projects since 2017 and is recognized as an expert in BIM and Project Management. He\u00A0has\u00A0contributed to research in information modeling and has participated in key international conferences on construction project optimization. Additionally, Nikita has extensive experience managing teams on large-scale industrial, infrastructural, and residential projects.`}
        </Typography>
        <Typography variant="body1"></Typography>

        <Typography variant="body1">
          With his passion, creativity, and strategic thinking, Nikita will help
          lead our team to success in the global market and make significant
          contributions to construction digital evolution.
        </Typography>
        <Typography variant="body1">
          Please join us in congratulating Nikita on his new role and in
          welcoming him to this exciting chapter in his career journey.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/nikita-pospelov-appointment/image-1.jpg"
            alt="Nikita Pospelov"
            title="Nikita Pospelov"
            placeholder="blurred"
          />
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default NikitaPospelovAppointment
